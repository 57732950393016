import Head from "next/head";

import Render from "_components/atoms/render";

const HeadModule = ( { isWebview } ) => {
	return (
		<Head>
			<meta
				content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				name    = "viewport"
			/>

			<script
				dangerouslySetInnerHTML = { {
					__html: "history.scrollRestoration = \"manual\""
				} }
			/>

			<Render condition = { !isWebview }>
				<script src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js" />

				<script src = "//imasdk.googleapis.com/js/sdkloader/ima3.js" />

			</Render>

		</Head>
	);

};

export default HeadModule;
